// @flow

import React from 'react';
import { useLocation, Routes, Route, Navigate } from 'react-router-dom';
import { deviceOsStrFunc } from '../lib/utils';
import StartMP from './mypath/StartMP/StartMP';
import FormMP from './mypath_peripartum/FormMP/FormMP';
import SecretDeveloperPage from './shared/SecretDeveloperPage';
import NavigationManager from './NavigationManager';
import LocalizedHelmet from './LocalizedHelmet';
import { isImpact, isUsing508Colors, isMyPath } from '../lib/appMode';

type PagesProps = {
  fullLexicon: Lexicon,
  routes: {
    [pageKey]: {
      component: React.FC,
      hideNavigation: boolean,
      hideNotes: boolean,
      isShowingInControlButton: boolean,
      hideP4YButton: boolean,
      navLinks: [string],
      back: string,
      next: string,
    },
  },
};

const deviceOsStr = deviceOsStrFunc();

const Pages: React.FC = ({ routes, fullLexicon }: PagesProps) => {
  const { pathname } = useLocation();
  const pageKey = pathname.slice(pathname.lastIndexOf('/') + 1) || 'splash';
  const currentPage = routes[pageKey];

  let layoutClasses;
  if (currentPage) {
    layoutClasses = `${process.env.REACT_APP_MODE} ${currentPage.additionalClasses || ''}`;
  }

  const accessibleColorClassName = isUsing508Colors ? 'accessible-colors' : '';
  const isMyPathClassName = isMyPath ? 'is-mypath' : '';

  /* calculate copies */
  const locale = pathname.startsWith('/es/') ? 'es' : 'en';
  const EnglishLexicon = fullLexicon.locale('en');
  const SpanishLexicon = fullLexicon.locale('es');
  const EnglishSharedLexicon = EnglishLexicon.subset('shared');
  const SpanishSharedLexicon = SpanishLexicon.subset('shared');

  const rootRedirectPath = isImpact ? '/form' : '/en/splash';

  return (
    <div
      className={`background-image ${pageKey} ${locale} ${layoutClasses} ${deviceOsStr} ${accessibleColorClassName} ${isMyPathClassName}`}
    >
      <Routes>
        {/* dev-only routes */}
        <Route path="/start/:friendly_id" element={<StartMP />} />
        <Route
          path="/:locale/secret-developer-page"
          element={<SecretDeveloperPage />}
        />
        <Route
          path="/:locale/secret_developer_page"
          element={<SecretDeveloperPage />}
        />

        <Route path="en">
          {Object.keys(routes).map((routeKey: string): React.element<*> => {
            const { Component, navLinks } = routes[routeKey];

            return (
              <Route
                key={routeKey}
                path={routeKey}
                element={
                  <Component
                    locale="en"
                    lexicon={EnglishLexicon.subset(routeKey)}
                    sharedLexicon={EnglishSharedLexicon}
                    navLinks={navLinks}
                  />
                }
              />
            );
          })}
        </Route>

        <Route path="es">
          {Object.keys(routes).map((routeKey: string): React.element<*> => {
            const { Component, navLinks } = routes[routeKey];

            return (
              <Route
                key={routeKey}
                path={routeKey}
                element={
                  <Component
                    locale="es"
                    lexicon={SpanishLexicon.subset(routeKey)}
                    sharedLexicon={SpanishSharedLexicon}
                    navLinks={navLinks}
                  />
                }
              />
            );
          })}
        </Route>

        {isImpact && <Route path="form" element={<FormMP />} />}

        <Route path="/" element={<Navigate to={rootRedirectPath} replace />} />

        <Route path="*" element={<h1>404: Page not found</h1>} />
      </Routes>

      <NavigationManager fullLexicon={fullLexicon} routes={routes} />

      <LocalizedHelmet fullLexicon={fullLexicon} />
    </div>
  );
};

export default Pages;
